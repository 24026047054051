import styled from "styled-components"
import { styles } from "../globals"

const BannerButton = styled.button`
  background: transparent;
  border: 0.2rem solid white;
  color: white;
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  ${styles.letterSpacing({ spacing: ".4rem" })};
  padding: 0.6rem 1.2rem;
  ${styles.transform({})};
  text-transform: uppercase;
  &:hover {
    color: black;
    background: white;
    cursor: pointer;
  }
`

export { BannerButton }
