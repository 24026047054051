import React, { Component } from "react"
import { Dialog } from "@reach/dialog"
import Img from "gatsby-image"
import styled from "styled-components"
import { styles } from "../globals"
import "@reach/dialog/styles.css"

export default class Lightbox extends Component {
  state = {
    selectedImage: null,
    showLightbox: false,
  }
  render() {
    const { images, thumbnails } = this.props
    const { selectedImage, showLightbox } = this.state
    return (
      <div>
        <LightboxWrapper className="gallery">
          {thumbnails.map((image, index) => (
            <PreviewButton
              key={index}
              type="button"
              onClick={() =>
                this.setState({
                  showLightbox: true,
                  selectedImage: images[index],
                })
              }
            >
              <Img fluid={image.node.childImageSharp.fluid} />
            </PreviewButton>
          ))}
        </LightboxWrapper>
        {showLightbox && (
          <DialogOverride
            className="dialog"
            onDismiss={() => this.setState({ showLightbox: false })}
          >
            <button
              type="button"
              onClick={() => this.setState({ showLightbox: false })}
            >
              X
            </button>
            <Img fluid={selectedImage.node.childImageSharp.fluid} />
          </DialogOverride>
        )}
      </div>
    )
  }
}

const DialogOverride = styled(Dialog)`
  &&[data-reach-dialog-overlay] {
    background: hsla(0, 0%, 0%, 0.8);
  }
  && {
    width: 100vw;
    padding: 0.6rem 0 0.3rem 0;

    @media (min-width: ${styles.xSmallScreenBreakpoint}) {
      width: 75vw;
      padding: 0.8rem;
    }
  }
`

const LightboxWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 0.2rem;
  .gatsby-image-wrapper {
    height: 35vh;
  }
  @media (min-width: ${styles.xSmallScreenBreakpoint}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.2rem;
  }
  @media (min-width: ${styles.smallScreenBreakpoint}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const PreviewButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`
