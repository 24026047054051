import React from "react"
import AdditionalInfo from "./AdditionalInfo"
import styled from "styled-components"
import { styles } from "../globals"

export default function IconsBanner({ children }) {
  return (
    <IconsBannerWrapper>
      <AdditionalInfo />
    </IconsBannerWrapper>
  )
}

const IconsBannerWrapper = styled.div`
  display: none;
  background: ${styles.darkGrey};
  color: ${styles.lightOrange};
  padding: 1rem 5rem;
  font-size: 1.2rem;
  @media (min-width: ${styles.mediumScreenBreakpoint}) {
    display: block;
  }
`
