import React from "react"
import Article from "../components/article/Article"
import Section from "../components/Section"
import Banner from "../components/Banner"
import { BannerButton } from "../components/Button"
import { FormattedMessage } from "react-intl"
import Gallery from "../components/Gallery"
import { HomeHeader } from "../components/header/"
import Layout from "../components/layout"
import LocalizedLink from "../components/LocalizedLink"
import InfoBanner from "../components/InfoBanner"
import img from "../images/header-image.jpg"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext: { locale }, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout locale={locale} pageContext={pageContext}>
      <SEO title="Hostel Ljubas" pathbame="/contact/" />
      <HomeHeader img={img}>
        <Banner subtitle="Blažići 23a, Marinići, 51216 Viškovo, Croatia">
          <BannerButton style={{ margin: "2rem auto" }}>
            <LocalizedLink to="/prices/">
              <FormattedMessage id="prices" />
            </LocalizedLink>
          </BannerButton>
        </Banner>
      </HomeHeader>
      <InfoBanner />
      <Section>
        <Article title={frontmatter.title}>{html}</Article>
      </Section>
      <Section>
        <Gallery />
      </Section>
    </Layout>
  )
}

export const indexQuery = graphql`
  query($path: String) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`
export default IndexPage
