import React from "react"
import Lightbox from "./Lightbox"
import { StaticQuery, graphql } from "gatsby"

const ALL_IMAGES = graphql`
  {
    getThumbsImages: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    getImages: allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default function Gallery() {
  return (
    <StaticQuery
      query={ALL_IMAGES}
      render={data => (
        <Lightbox
          thumbnails={data.getThumbsImages.edges}
          images={data.getImages.edges}
        />
      )}
    />
  )
}
